@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'libs/framework/ui-core/styles/main';
@import "../../../../../libs/softapps/core/styles/main";
@import 'libs/framework/dynamic/styles/main';
@import 'libs/framework/capacitor/styles/main';

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts" !default;
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/v4-shims';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';


:root {
  --colorPrimary: #377E3F;
  --colorPrimary50: #9AD3A0;
  --colorPrimary100: #8CCD93;
  --colorPrimary200: #6FC078;
  --colorPrimary300: #53B45E;
  --colorPrimary400: #439A4D;
  --colorPrimary500: #377E3F;
  --colorPrimary600: #26572B;
  --colorPrimary700: #153018;
  --colorPrimary800: #040904;
  --colorPrimary900: #000000;

  --colorSelected: #c3d9ca;

  --publicShellBranding: url('/assets/images/eisl-logo.png');
  --publicShellBrandingBackground: var(--colorSurface);
  --publicShellBrandingHeight: 90px;
  --publicShellBrandingSize: 110px;

  --sideMenuBranding: url('/assets/images/eisl-logo.png');
  --sideMenuBrandingSize: 140px;
}
